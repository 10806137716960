(function () {
    'use strict';
    angular
        .module('atheer.services')
        .factory('NotificationHandleService', NotificationHandleService);

    /* @ngInject */
    function NotificationHandleService($mdDialog, $mdToast, $filter, $state, ToastService, toastConstants) {

        var service = {
            onNotification: onNotification
        };

        return service;

        function onNotification(message) {
            if (message.type == "instant_session_invite") {
                sessionInvite(message);
            } else if (message.type == "scheduled_session_invite") {
                sessionScheduleInvite(message);
            } else if (message.type == "group_session_invite") {
                groupSessionInvite(message)
            } else if (message.message.type == "MESSAGE_CREATED") {
                onReceiveMessage(message.message);
            } else if (message.message.type == "JOB") {
                jobMessageHandler(message.message);
            } else if (message.message.type == "TICKET") {
                ticketMessageHandler(message.message);
            }
        }


        function sessionScheduleInvite(message) {
            var sessionObj = JSON.parse(message.body);
            ToastService.displayToast($filter('translate')('notification.session.scheduled', { sessionNo: sessionObj.session_no }));
        }



        function sessionInvite(message) {
            if ($state.current.url == "/player/:session_no") {
                var sessionObj = JSON.parse(message.body);
                ToastService.displayToastWithAction($filter('triTranslate')('notification.session.joinInstantSession') + message.userInfo.firstName + " " + message.userInfo.lastName,
                    $filter('triTranslate')('entity.action.join'), 'md-accent', 3000);
            } else {
                $mdToast.show({
                    content: $filter('triTranslate')('notification.session.joinInstantSession') + message.userInfo.firstName + ' ' + message.userInfo.lastName,
                    hideDelay: 8000,
                    position: toastConstants.position,
                    controller: 'sessionNotifierController',
                    controllerAs: "vm",
                    templateUrl: 'modules/session/session-notifier.tmpl.html',
                    highlightClass: 'md-accent',
                    locals: {
                        notification: message
                    },
                });
            }
        }

        function groupSessionInvite(message) {
            if ($state.current.url == "/player/:session_no") {
                var sessionObj = JSON.parse(message.body);
                ToastService.displayToastWithAction($filter('triTranslate')('notification.session.joinGroupSession') + message.userInfo.firstName + " " + message.userInfo.lastName,
                    $filter('triTranslate')('entity.action.join'), 'md-accent', 3000);
            } else {
                $mdToast.show({
                    content: $filter('triTranslate')('notification.session.joinGroupSession') + message.userInfo.firstName + ' ' + message.userInfo.lastName,
                    hideDelay: 8000,
                    position: toastConstants.position,
                    controller: 'sessionNotifierController',
                    controllerAs: "vm",
                    templateUrl: 'modules/session/session-notifier.tmpl.html',
                    highlightClass: 'md-accent',
                    locals: {
                        notification: message
                    },
                });
            }
        }

        function onReceiveMessage(message) {
            $mdToast.show({
                hideDelay: 8000,
                position: toastConstants.position,
                controller: 'conversationMessageNotifier',
                controllerAs: "vm",
                templateUrl: 'modules/conversation/conversation-message-notifier.tmpl.html',
                highlightClass: 'md-accent',
                locals: {
                    notification: message
                },
            });
        }

        function jobMessageHandler(message) {
            var jobObj = JSON.parse(message.data.body);

            var alertMessage = jobObj.job_number + ' - ' + jobObj.airform_name;

            if (message.data.type == "new_job_assignment") {
                alertMessage = $filter('triTranslate')('notification.job.jobAssignedEvent') + jobObj.job_number + ' - ' + jobObj.airform_name;
            } else if (message.data.type == "updated_job_assignment") {
                alertMessage = $filter('triTranslate')('notification.job.jobUpdatedEvent') + jobObj.job_number + ' - ' + jobObj.airform_name;
            } else if (message.data.type == "aborted_job_assignment") {
                alertMessage = $filter('triTranslate')('notification.job.jobAbortedEvent') + jobObj.job_number + ' - ' + jobObj.airform_name;
            } else if (message.data.type == "completed_job_assignment") {
                alertMessage = $filter('triTranslate')('notification.job.jobCompletedEvent') + jobObj.job_number + ' - ' + jobObj.airform_name;
            } else if (message.data.type == "job_due_reminder") {
                alertMessage = $filter('triTranslate')('notification.job.jobDueReminderEvent') + jobObj.job_number + ' - ' + jobObj.airform_name;
            } else if (message.data.type == "notification_alert") {
                alertMessage = jobObj.job_number + ' - ' + jobObj.airform_name + ' : ' + jobObj.message;
            }
            ToastService.displayToast(alertMessage, 3000);
        }

        function ticketMessageHandler(message) {
            var ticketObj = JSON.parse(message.data.body);

            var alertMessage = ticketObj.ticket_number + ' - ' + ticketObj.ticket_template_name;

            if (message.data.type == "ticket_assigned") {
                alertMessage = $filter('triTranslate')('notification.ticket.ticketAssignedEvent') + ticketObj.ticket_number + ' - ' + ticketObj.ticket_template_name;
            } else if (message.data.type == "ticket_updated") {
                alertMessage = $filter('triTranslate')('notification.ticket.ticketUpdatedEvent') + ticketObj.ticket_number + ' - ' + ticketObj.ticket_template_name;
            } else if (message.data.type == "ticket_created") {
                alertMessage = $filter('triTranslate')('notification.ticket.ticketCreatedEvent') + ticketObj.ticket_number + ' - ' + ticketObj.ticket_template_name;
            } else if (message.data.type == "ticket_comment") {
                alertMessage = ticketObj.ticket_number + ' - ' + ticketObj.ticket_template_name + ' : ' + ticketObj.message;
            }
            ToastService.displayToast(alertMessage, 3000);
        }
    }
})();
