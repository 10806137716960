(function () {
    'use strict';

    angular
        .module('atheer.system')
        .controller('LensHomePageConfigController', LensHomePageConfigController)

    /* @ngInject */
    function LensHomePageConfigController($scope, $q, $state, $http, $filter, ToastService, triSettings, Upload, Setting, Content) {
        var vm = this;

        vm.loadingData = false;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.lensHomepageConfigFile;
        vm.configFileName;
        vm.isFileUploadInProgress = false;
        vm.fileMaxSize = 2 * 1024 * 1024;
        vm.loadingData = false;
        vm.selectedHomeScreenConfig = 'lens';
        vm.embedScript = null;
        vm.iconUrl = "https://i.ibb.co/ZfWMD0m/Vector.png";
        vm.themeColor = triSettings.getSkinModel().primary_color_hex;
        vm.bottom = "0";
        vm.right = "0";


        vm.upload = function (files) {
            if (files.length) {
                var file = files[0];
                if (file.size < vm.fileMaxSize) {
                    vm.isFileUploadInProgress = true;
                    Upload.upload({
                        url: vm.selectedHomeScreenConfig === 'studio' ? 'api/homepage/upload?homepageType=Web' : vm.selectedHomeScreenConfig === 'lens' ? 'api/homepage/upload': 'api/co-pilot-commands/upload',
                        data: {
                            'file': file,
                            'type': 'file'
                        },
                        arrayKey: ''
                    }).then(function (resp) {
                        uploadComplete(resp);
                    }, function (resp) {
                        uploadError();
                    });
                } else {
                    uploadError();
                }
            }
        }

        vm.setDefault = function () {
            vm.isFileUploadInProgress = true;
            var endURL = 'api/homepage/generate-default';
            if (vm.selectedHomeScreenConfig === 'studio') {
                endURL += '?homepageType=Web';
            }
            $http({
                url: endURL,
                method: 'POST',
                transformResponse: [function (data) {
                    return data;
                }]
            }).then(function (resp) {
                vm.isFileUploadInProgress = false;
                vm.lensHomepageConfigFile = resp.data;
                vm.configFileName = vm.lensHomepageConfigFile.split('|')[0];
                ToastService.displayToast($filter('triTranslate')('atheer.homepageconfig.defaultConfigSuccess'));
            }, function (error) {
                vm.isFileUploadInProgress = false;
                ToastService.displayToast($filter('triTranslate')('atheer.homepageconfig.defaultConfigFailure'));
            });
        }

        vm.downloadSampleFile = function () {
            downloadFile('sample_lens_home_page_config.json', 'api/homepage/download/sample')
        }

        vm.downloadConfigFile = function () {
            var fileUrl;
            var mediaId;

            if ( vm.selectedHomeScreenConfig === 'command') {
                fileUrl = 'api/co-pilot-commands/download';
            }
            else {
                mediaId = vm.lensHomepageConfigFile.split('|')[1];
                fileUrl = 'media/documents/' + mediaId + '.json';
            }
            downloadFile(vm.configFileName, fileUrl);
        }

        vm.tabSelected = function (tab) {
            vm.selectedHomeScreenConfig = tab;
            vm.loadingData = true;
            loadData();
            vm.loadingData = false;
        }

        function downloadFile(fileName, fileUrl) {
            $http.get(fileUrl, { responseType: 'blob' }).then(function (response) {
                var objectUrl = URL.createObjectURL(response.data);
                var link = document.createElement('a');
                link.download = fileName,
                    link.href = objectUrl;
                link.click();
            })
        }

        function uploadComplete(resp) {
            vm.isFileUploadInProgress = false;
            if ( vm.selectedHomeScreenConfig === 'command') {
                vm.configFileName = 'ai_commands.json';
            }
            else {
                vm.configFileName = resp.data.source_file_name;
                vm.lensHomepageConfigFile = resp.data.source_file_name + '|' + resp.data.id;
            }
            ToastService.displayToast($filter('triTranslate')('atheer.homepageconfig.configUpdateSuccess'));
        }

        function uploadError(resp) {
            vm.isFileUploadInProgress = false;
            ToastService.displayToast($filter('triTranslate')('atheer.homepageconfig.configUpdateFailed'));
        }

        function setConfigModel(skinSettings) {
            var fieldKey = 'lense_homepage_json_file';
            if (vm.selectedHomeScreenConfig === 'studio') {
                fieldKey = 'web_homepage_json_file';
            } else if ( vm.selectedHomeScreenConfig === 'command')
            {
                fieldKey = 'ai_commands_json_file';
                vm.configFileName = 'ai_commands.json';
            }

            angular.forEach(skinSettings, function (setting) {
                if (setting.category == 'skin_info') {
                    vm.skinSettings = setting;
                    angular.forEach(setting.fields, function (settingField) {
                        if (settingField.value != null && settingField.name == fieldKey) {
                            vm.lensHomepageConfigFile = settingField.value;
                            vm.configFileName = vm.lensHomepageConfigFile.split('|')[0];
                        }
                    });
                }
            });
        }

        function loadData() {
            vm.loadingData = true;

            $q.all([
                Setting.getSkinSettings().$promise,
                Content.query({
                    query: 'type==EMAIL_LAYOUT'
                }).$promise
            ])
                .then(function (results) {
                    setConfigModel(results[0]);
                    vm.loadingData = false;
                });
        };
        loadData();

        vm.generateScript = function () {
            vm.embedScript = "<script src='https://app.atheer."+window.location.origin.split(".").pop()+"/embed/embed_copilot.js' data-atheerdomain='"+window.location.origin+"' data-iconurl='"+vm.iconUrl+"' data-themecolor='"+vm.themeColor+"' data-bottom='"+vm.bottom+"' data-right='"+vm.right+"' > </script>";
        };
        vm.onCopySuccess = function (e) {
            ToastService.displayToast(
                $filter("triTranslate")(
                    "atheer.homepageconfig.copilotEmbedScriptCopied"
                )
            );
        };
    }
})();