(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('LLMConfigController', LLMConfigController)

    /* @ngInject */
    function LLMConfigController($mdDialog, $q, $scope, $filter, $sce, ParseLinks, $state, AlertService, ContentAction, $mdToast, $mdSidenav, Principal, Setting, Feature, ToastService) {
        var vm = this;

        vm.pageTitleKey = $state.$current.data.pageTitle;

        vm.llmConfigModel = {
            id: null,
            temperature: "0",
            seed: "10001",
            n: "1",
            completions_model_id: "",
            vision_preview_model_id: "",
            vision_preview_image_detail: "",
            airform_preview_model_id: "",
            embeddings_model: "",
            qNa_model: "",
            vector_score_threshold: "",
            vector_results_limit: "",
            vector_num_candidates: "",
            qNa_thread_history_to_consider: ""
        };

        vm.onSaveSuccess = function(result) {
            vm.llmConfigModel = result;
            vm.llmConfigSetting = result;
            vm.isSaving = false;
            AlertService.displayToast($mdToast);
        };

        vm.onSaveError = function() {
            vm.isSaving = false;
        };

        function setModelData() {
            setSettingField("temperature", vm.llmConfigModel.temperature);
            setSettingField("seed", vm.llmConfigModel.seed);
            setSettingField("n", vm.llmConfigModel.n);
            setSettingField("completions_model_id", vm.llmConfigModel.completions_model_id);
            setSettingField("vision_preview_model_id", vm.llmConfigModel.vision_preview_model_id);
            setSettingField("vision_preview_image_detail", vm.llmConfigModel.vision_preview_image_detail);
            setSettingField("airform_preview_model_id", vm.llmConfigModel.airform_preview_model_id);
            setSettingField("embeddings_model", vm.llmConfigModel.embeddings_model);
            setSettingField("qNa_model", vm.llmConfigModel.qNa_model);
            setSettingField("vector_score_threshold", vm.llmConfigModel.vector_score_threshold);
            setSettingField("vector_results_limit", vm.llmConfigModel.vector_results_limit);
            setSettingField("vector_num_candidates", vm.llmConfigModel.vector_num_candidates);
            setSettingField("qNa_thread_history_to_consider", vm.llmConfigModel.qNa_thread_history_to_consider);
            
        }

        function setSettingField(fieldName, fieldValue) {
            var foundField = false;
            angular.forEach(vm.llmConfigSetting.fields, function(settingField) {
                if (settingField.name == fieldName) {
                    settingField.value = fieldValue;
                    foundField = true;
                }
            });

            if (!foundField) {
                var settingField = {
                    name: fieldName,
                    value: fieldValue,
                }
                vm.llmConfigSetting.fields.push(settingField);
            }
        }

        vm.save = function() {
            setModelData();

            Setting.update(vm.llmConfigSetting, function() {
                ToastService.displayToast($filter('triTranslate')("atheer.setting.success"), 3000);
            });
        }



        vm.loadData = function() {
            vm.loadingData = true;

            $q.all([
                    Setting.getLLMConfigSettings().$promise
                ])
                .then(function(results) {
                    setLLMConfigModel(results[0]);
                    vm.loadingData = false;
                });
        };

        function setLLMConfigModel(settings) {

            angular.forEach(settings, function(setting) {
                if (setting.category == 'llm_parameters') {
                    vm.llmConfigSetting = setting;

                    angular.forEach(setting.fields, function(settingField) {
                        if (settingField.name == 'temperature') {
                           vm.llmConfigModel.temperature = settingField.value;
                        }
                        if (settingField.name == 'seed') {
                            vm.llmConfigModel.seed = settingField.value;
                        }
                        if (settingField.name == 'n') {
                            vm.llmConfigModel.n = settingField.value;
                        }
                        if (settingField.name == 'completions_model_id') {
                            vm.llmConfigModel.completions_model_id = settingField.value;
                        }
                        if (settingField.name == 'vision_preview_model_id') {
                            vm.llmConfigModel.vision_preview_model_id = settingField.value;
                        }
                        if (settingField.name == 'vision_preview_image_detail') {
                            vm.llmConfigModel.vision_preview_image_detail = settingField.value;
                        }
                        if (settingField.name == 'airform_preview_model_id') {
                            vm.llmConfigModel.airform_preview_model_id = settingField.value;
                        }
                        if (settingField.name == 'embeddings_model') {
                            vm.llmConfigModel.embeddings_model = settingField.value;
                        }
                        if (settingField.name == 'qNa_model') {
                            vm.llmConfigModel.qNa_model = settingField.value;
                        }
                        if (settingField.name == 'vector_score_threshold') {
                            vm.llmConfigModel.vector_score_threshold = settingField.value;
                        }
                        if (settingField.name == 'vector_results_limit') {
                            vm.llmConfigModel.vector_results_limit = settingField.value;
                        }
                        if (settingField.name == 'vector_num_candidates') {
                            vm.llmConfigModel.vector_num_candidates = settingField.value;
                        }
                        if (settingField.name == 'qNa_thread_history_to_consider') {
                            vm.llmConfigModel.qNa_thread_history_to_consider = settingField.value;
                        }
                    });
                }
            });
        };

        vm.loadData();
    }
})();
